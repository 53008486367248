import AppSelect from '@theme/components/atom/AppSelect'
import AppSelectMixin from '~/mixins/AppSelectMixin'

export default {
  components: { AppSelect },
  mixins: [AppSelectMixin],
  computed: {
    options() {
      return this.$store.state.globals.countries
    },
  },
}
